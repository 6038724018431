/* eslint-disable no-console*/
import 'custom-event-polyfill'
import React from 'react'
import loadable from '@loadable/component'
import { PersistGate } from 'redux-persist/integration/react'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import AppExperiment from './components/AppExperiment'
import { store, persistor, history } from './store/configureStore'
import { Amplify } from 'aws-amplify'
import {
  COGNITO_CLIENT_ID,
  COGNITO_USER_POOL_ID,
  COGNITO_REGION,
  COGNITO_APP_DOMAIN,
  COGNITO_REDIRECT_SIGNIN_URL,
  COGNITO_REDIRECT_SIGNOUT_URL
} from 'constants/configs/auth'

Amplify.configure({
  Auth: {
    region: COGNITO_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_CLIENT_ID
  },
  oauth: {
    domain: COGNITO_APP_DOMAIN,
    redirectSignIn: COGNITO_REDIRECT_SIGNIN_URL,
    redirectSignOut: COGNITO_REDIRECT_SIGNOUT_URL,
    responseType: 'code'
  }
})

// eslint-disable-next-line no-undef
require('./favicon.ico')

const appElement = document.getElementById('app')
hydrate(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <AppExperiment />
      </Router>
    </PersistGate>
  </Provider>,
  appElement
)
appElement.classList.remove('hidden')
document.getElementById('static-facade').classList.add('hidden')
