import React from 'react'
import { useSelector } from 'react-redux'
import {
  completedEyeQuizSelector,
  completedSerumQuizSelector,
  completedSkinQuizSelector
} from '../utils/selectors'
import { pathOr } from 'ramda'
import {
  hasAccessToPath,
  getRedirectionByEventNameOrPath
} from '../utils/helpers/userTypeAccessHelper'
import {
  useIsEyeCreamDuoSubscriber,
  useIsSerumSubscriber,
  useIsSystemSubscriber,
  useSubscriptionsInfo
} from 'services/shopify/hooks'

function useUserTypeAccess() {
  const { activeSubscriptions } = useSubscriptionsInfo()
  const isSystemSubscriber = useIsSystemSubscriber()
  const isEyeCreamDuoSubscriber = useIsEyeCreamDuoSubscriber()
  const isSerumSubscriber = useIsSerumSubscriber()

  const orders = useSelector(state => pathOr([], ['account', 'orders'], state))
  const completedSkinQuiz = useSelector(state => completedSkinQuizSelector(state))
  const completedEyeQuiz = useSelector(state => completedEyeQuizSelector(state))
  const completedSerumQuiz = useSelector(state => completedSerumQuizSelector(state))

  const hasAccessTo = path => {
    return hasAccessToPath(path, {
      activeSubscriptions,
      orders,
      completedSkinQuiz,
      completedEyeQuiz,
      completedSerumQuiz,
      isSystemSubscriber,
      isEyeCreamDuoSubscriber,
      isSerumSubscriber
    })
  }

  const getRedirectionPath = path => {
    return getRedirectionByEventNameOrPath(path, {
      activeSubscriptions,
      orders,
      completedSkinQuiz,
      completedEyeQuiz,
      completedSerumQuiz
    })
  }

  const hasRedirection = path => !!getRedirectionPath(path)

  return {
    hasAccessTo,
    hasRedirection,
    getRedirectionPath
  }
}

export const withUserTypeAccess = WrappedComponent => props => {
  const { hasAccessTo, hasRedirection, getRedirectionPath } = useUserTypeAccess()
  return (
    <WrappedComponent
      {...props}
      hasAccessTo={hasAccessTo}
      hasRedirection={hasRedirection}
      getRedirectionPath={getRedirectionPath}
    />
  )
}

export default useUserTypeAccess
