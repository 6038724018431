import { useSelector } from 'react-redux'
import { combinedProductsSelector } from 'utils/selectors'
import {
  EYE_CREAM_PRODUCT,
  SYSTEM_PRODUCT,
  CLEANSER_PRODUCT,
  DAY_EYE_CREAM_PRODUCT,
  DAY_MOISTURIZER_PRODUCT,
  NIGHT_CREAM_PRODUCT,
  NIGHT_EYE_CREAM_PRODUCT,
  SERUM_PRODUCT,
  HYDRATION_BOOST_DAY_MOISTURIZER,
  SPOT_TREATMENT_SERUM,
  MICELLAR_WATER,
  PILLOW_CASE
} from '../constants/products'
import {
  getDefaultEyeCreamDuoSubscriptionPrice,
  getEyeCreamDuoProduct,
  getSystemProduct,
  getDefaultSystemSubscriptionPrice
} from 'utils/helpers'
import {
  DEFAULT_EYE_CREAM_DUO_SUBSCRIPTION_PERIOD,
  DEFAULT_SERUM_SUBSCRIPTION_PERIOD,
  DEFAULT_SUBSCRIPTION_PERIOD_UNIT,
  DEFAULT_SYSTEM_SUBSCRIPTION_PERIOD
} from '../constants/subscriptions'
import { getDefaultSerumSubscriptionPrice, getSerumProduct } from '../utils/helpers'

const DEFAULT_SYSTEM_PERIODICITY_QUERY = {
  periodicity: 'subscription',
  period: DEFAULT_SYSTEM_SUBSCRIPTION_PERIOD,
  periodUnit: DEFAULT_SUBSCRIPTION_PERIOD_UNIT
}
const DEFAULT_EYE_PERIODICITY_QUERY = {
  periodicity: 'subscription',
  period: DEFAULT_EYE_CREAM_DUO_SUBSCRIPTION_PERIOD,
  periodUnit: DEFAULT_SUBSCRIPTION_PERIOD_UNIT
}

const DEFAULT_SERUM_PERIODICITY_QUERY = {
  periodicity: 'subscription',
  period: DEFAULT_SERUM_SUBSCRIPTION_PERIOD,
  periodUnit: DEFAULT_SUBSCRIPTION_PERIOD_UNIT
}

const ACCESSORY_PERIODICITY_QUERY = {
  periodicity: 'one-time'
}

export const DEFAULT_PERIODICITY_QUERY_BY_PRODUCT_ID = {
  [SYSTEM_PRODUCT]: DEFAULT_SYSTEM_PERIODICITY_QUERY,
  [DAY_MOISTURIZER_PRODUCT]: DEFAULT_SYSTEM_PERIODICITY_QUERY,
  [NIGHT_CREAM_PRODUCT]: DEFAULT_SYSTEM_PERIODICITY_QUERY,
  [CLEANSER_PRODUCT]: DEFAULT_SYSTEM_PERIODICITY_QUERY,
  [EYE_CREAM_PRODUCT]: DEFAULT_EYE_PERIODICITY_QUERY,
  [NIGHT_EYE_CREAM_PRODUCT]: DEFAULT_EYE_PERIODICITY_QUERY,
  [DAY_EYE_CREAM_PRODUCT]: DEFAULT_EYE_PERIODICITY_QUERY,
  [SERUM_PRODUCT]: DEFAULT_SERUM_PERIODICITY_QUERY,
  [HYDRATION_BOOST_DAY_MOISTURIZER]: ACCESSORY_PERIODICITY_QUERY, // accessory
  [SPOT_TREATMENT_SERUM]: ACCESSORY_PERIODICITY_QUERY, // accessory
  [MICELLAR_WATER]: ACCESSORY_PERIODICITY_QUERY, // accessory
  [PILLOW_CASE]: ACCESSORY_PERIODICITY_QUERY // accessory
}

const STRATEGIES = ['id', 'period', 'defaultPrice']

// Assumes that priceId is globally unique across the store
const findById = ({ priceId }, products) => {
  if (!priceId) return null

  // eslint-disable-next-line no-unused-vars
  for (let product of products) {
    const oneTimePrice = product?.one_time_price
    const subscriptionPrice = product?.subscription_prices?.find(price => price.id === priceId)

    if (oneTimePrice?.id === priceId) return oneTimePrice
    if (subscriptionPrice?.id === priceId) return subscriptionPrice
  }

  return null
}

const findByPeriod = ({ productId, periodicity, period, periodUnit }, products) => {
  if (!productId) return null
  if (periodicity !== 'one-time' && periodicity !== 'subscription') return null

  const product = products.find(({ id }) => id === productId)

  if (!product) return null
  if (periodicity === 'one-time') return product?.one_time_price || null
  return (
    product?.subscription_prices?.find(
      sp => sp.period === period && sp.period_unit === periodUnit
    ) || null
  )
}

const findByDefaultPrice = ({ productId, oneTime }, products) => {
  if (!productId) return null

  let product, getSubscription

  if (productId === EYE_CREAM_PRODUCT) {
    product = getEyeCreamDuoProduct(products)
    getSubscription = getDefaultEyeCreamDuoSubscriptionPrice
  } else if (productId === SYSTEM_PRODUCT) {
    product = getSystemProduct(products)
    getSubscription = getDefaultSystemSubscriptionPrice
  } else if (productId === SERUM_PRODUCT) {
    product = getSerumProduct(products)
    getSubscription = getDefaultSerumSubscriptionPrice
  } else if (productId && productId in DEFAULT_PERIODICITY_QUERY_BY_PRODUCT_ID) {
    const query = oneTime
      ? { periodicity: 'one-time', productId }
      : { ...DEFAULT_PERIODICITY_QUERY_BY_PRODUCT_ID[productId], productId }
    return findByPeriod(query, products)
  } else {
    throw new Error(`Default price not implemented for '${productId}'.`)
  }
  return (oneTime ? product.one_time_price : getSubscription(product)) || null
}

// A hook for finding a price given a query.
// The idea is to expand the query params over time and add conditional logic
// to support multiple ways of finding a price. Hopefully, this "overloaded"
// approach doesn't get too unwieldy...
//
// If we ever transition to TypeScript, we can make the overloaded type signatures
// explicit, which can be used by IDEs to make the API more clear.
// TODO check if this hook is need if does, need to use shopify API
export const usePrice = () => {
  const products = useSelector(combinedProductsSelector)
  return ({ strategy, query }) => {
    if (!STRATEGIES.includes(strategy))
      throw new Error(`strategy must be one of: ${STRATEGIES.join(', ')}`)

    if (strategy === 'id') return findById(query, products)
    if (strategy === 'period') return findByPeriod(query, products)
    if (strategy === 'defaultPrice') return findByDefaultPrice(query, products)
  }
}
