import { KEYWORD_EYE, KEYWORD_SKIN } from '../constants/funnels'
import { useContext } from 'react'
import { IntentAndFunnelsContext } from '../providers/IntentAndFunnelsProvider'

function usePathBasedOnIntent() {
  const { intent, getTargetRoute } = useContext(IntentAndFunnelsContext)

  const getCtaPathBasedOnIntent = () => getTargetRoute(intent)

  // TODO THIS LOGIC MISS SERUM QUIZ
  const getQuizPathBasedOnIntent = () =>
    `/quiz${(intent || KEYWORD_SKIN) === KEYWORD_EYE ? '/' + KEYWORD_EYE + '/start' : '/start'}`

  return { getCtaPathBasedOnIntent, getQuizPathBasedOnIntent }
}

export default usePathBasedOnIntent
